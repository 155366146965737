// Generated by Framer (18f6785)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["O4M621S3i", "YpjUF6viN", "VS5ahQTHl"];

const serializationHash = "framer-02jfa"

const variantClassNames = {O4M621S3i: "framer-v-1c4v67o", VS5ahQTHl: "framer-v-11r4ktd", YpjUF6viN: "framer-v-3wanp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Desktop: "O4M621S3i", Phone: "VS5ahQTHl", Tablet: "YpjUF6viN"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "O4M621S3i"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "O4M621S3i", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1c4v67o", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"O4M621S3i"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-17661574-4735-4924-aa9b-eca669bb69a4, rgb(255, 255, 255))", ...style}} {...addPropertyOverrides({VS5ahQTHl: {"data-framer-name": "Phone"}, YpjUF6viN: {"data-framer-name": "Tablet"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-02jfa.framer-1scodq7, .framer-02jfa .framer-1scodq7 { display: block; }", ".framer-02jfa.framer-1c4v67o { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 40px; height: min-content; justify-content: center; min-height: 56px; overflow: visible; padding: 0px; position: relative; width: 1200px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-02jfa.framer-1c4v67o { gap: 0px; } .framer-02jfa.framer-1c4v67o > * { margin: 0px; margin-left: calc(40px / 2); margin-right: calc(40px / 2); } .framer-02jfa.framer-1c4v67o > :first-child { margin-left: 0px; } .framer-02jfa.framer-1c4v67o > :last-child { margin-right: 0px; } }", ".framer-02jfa.framer-v-11r4ktd.framer-1c4v67o { min-height: 72px; width: 390px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 56
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"YpjUF6viN":{"layout":["fixed","auto"]},"VS5ahQTHl":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerMJLb199pX: React.ComponentType<Props> = withCSS(Component, css, "framer-02jfa") as typeof Component;
export default FramerMJLb199pX;

FramerMJLb199pX.displayName = "Disclaimer";

FramerMJLb199pX.defaultProps = {height: 56, width: 1200};

addPropertyControls(FramerMJLb199pX, {variant: {options: ["O4M621S3i", "YpjUF6viN", "VS5ahQTHl"], optionTitles: ["Desktop", "Tablet", "Phone"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerMJLb199pX, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})